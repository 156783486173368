var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"py-5 text-center container"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticStyle:{"display":"none"}},[_c('img',{attrs:{"src":require("../assets/ag-bp_logo_trans.png")},on:{"load":_vm.handleLoad}}),_c('img',{attrs:{"src":require("../assets/image_012.jpeg")},on:{"load":_vm.handleLoad}}),_c('img',{attrs:{"src":require("../assets/image_010.jpeg")},on:{"load":_vm.handleLoad}}),_c('img',{attrs:{"src":require("../assets/image_011.jpeg")},on:{"load":_vm.handleLoad}}),_c('img',{attrs:{"src":require("../assets/image_002.jpeg")},on:{"load":_vm.handleLoad}}),_c('img',{attrs:{"src":require("../assets/image_003.jpeg")},on:{"load":_vm.handleLoad}}),_c('img',{attrs:{"src":require("../assets/image_008.jpeg")},on:{"load":_vm.handleLoad}}),_c('img',{attrs:{"src":require("../assets/image_007.jpeg")},on:{"load":_vm.handleLoad}})]),_vm._m(0),_vm._m(1)],1),_c('hr',{staticClass:"featurette-divider"}),_vm._m(2),_c('hr',{staticClass:"featurette-divider"}),_vm._m(3),_c('hr',{staticClass:"featurette-divider"}),_vm._m(4),_c('hr',{staticClass:"featurette-divider"}),_vm._m(5),_c('hr',{staticClass:"featurette-divider"}),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row py-lg-3"},[_c('div',{staticClass:"col-lg-8 col-md-8 mx-auto mt-2 mb-2"},[_c('img',{staticClass:"logo-main",attrs:{"alt":"Vue logo","src":require("../assets/ag-bp_logo_trans.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 col-md-10 mx-auto mt-2 mb-2"},[_c('h1',{staticClass:"fw-light mb-4 text-uppercase"},[_c('b',{staticStyle:{"font-weight":"800"}},[_vm._v("¿Quiénes somos?")])]),_c('p',{staticClass:"lead text-muted"},[_vm._v(" Somos un consorcio familiar reconocido con más de 20 años de experiencia, brindando a nuestros clientes soluciones y servicios eficientes a sus necesidades financieras y de optimización operativa. ")]),_c('p',{staticClass:"lead text-muted"},[_vm._v(" En el año 2001 constituimos nuestra primer empresa denominada GONZÁLEZ MÁRQUEZ ASESORES, S.C. Despacho dedicado a la asesoría financiera y operativa de diversas firmas reconocidas a nivel regional. ")]),_c('p',{staticClass:"lead text-muted"},[_vm._v(" En el año 2022 constituimos JUST AUTO AG, S.A. DE C.V., empresa dedicada a la reparación y estética automotriz. Nuestro mercado objetivo los autos PREMIUM. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5 text-center container",staticStyle:{"background-color":"#f3f3f3"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 col-md-10 mx-auto mt-2 mb-2"},[_c('h1',{staticClass:"fw-light mb-4 text-uppercase"},[_c('b',{staticStyle:{"font-weight":"800"}},[_vm._v("Con más de 10 años")]),_c('br'),_vm._v("de experiencia en el sector")]),_c('p',{staticClass:"lead text-muted"},[_vm._v(" Somos una empresa con más que el conocimiento y la experiencia en la reparación de autos en colisión. Nuestra dedicación está basada en el compromiso con la calidad de cada uno de los trabajos recibidos. ")]),_c('p',{staticClass:"lead text-muted"},[_vm._v(" Gracias a ello, hoy decimos con toda seguridad que somos capaces de cumplir con los compromisos y altos estándares requeridos por nuestros clientes. ")]),_c('p',{staticClass:"lead text-muted"},[_vm._v(" Es de esta forma que logramos ser una empresa dinámica que se adapta a los requerimientos actuales del sector. ")]),_c('img',{staticClass:"img-thumbnail rounded",attrs:{"src":require("../assets/image_012.jpeg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5 text-center container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 col-md-10 mx-auto mt-2 mb-2"},[_c('h1',{staticClass:"fw-light mb-4 text-uppercase"},[_vm._v("Misión, visión y valores")]),_c('img',{staticClass:"img-thumbnail rounded",attrs:{"src":require("../assets/image_010.jpeg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5 text-center container",staticStyle:{"background-color":"#f3f3f3"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 col-md-10 mx-auto mt-2 mb-2"},[_c('h1',{staticClass:"fw-light mb-4 text-uppercase"},[_c('b',{staticStyle:{"font-weight":"800"}},[_vm._v("Nuestro recurso humano")]),_c('br'),_vm._v("dedicados y expertos en el rubro")]),_c('img',{staticClass:"img-thumbnail rounded",attrs:{"src":require("../assets/image_011.jpeg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5 text-center container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 col-md-10 mx-auto mt-2 mb-2"},[_c('h1',{staticClass:"fw-light mb-4 text-uppercase"},[_c('b',{staticStyle:{"font-weight":"800"}},[_vm._v("Instalaciones")]),_c('br'),_vm._v("y materiales de trabajo")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 mx-auto"},[_c('img',{staticClass:"img-thumbnail img-fluid",attrs:{"src":require("../assets/image_002.jpeg")}})]),_c('div',{staticClass:"col-lg-6 col-md-6 mx-auto"},[_c('img',{staticClass:"img-thumbnail img-fluid",attrs:{"src":require("../assets/image_003.jpeg")}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 mx-auto"},[_c('img',{staticClass:"img-thumbnail img-fluid",attrs:{"src":require("../assets/image_008.jpeg")}})]),_c('div',{staticClass:"col-lg-6 col-md-6 mx-auto"},[_c('img',{staticClass:"img-thumbnail img-fluid",attrs:{"src":require("../assets/image_007.jpeg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"py-5 text-center container",staticStyle:{"background-color":"#f3f3f3"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 col-md-10 mx-auto mt-2 mb-2"},[_c('h1',{staticClass:"fw-light mb-4 text-uppercase"},[_c('b',{staticStyle:{"font-weight":"800"}},[_vm._v("Algunas empresas")]),_c('br'),_vm._v("con las que hemos trabajado")])])])])
}]

export { render, staticRenderFns }