import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Vue from "vue"
import App from "./App.vue"
import router from "./router"

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import VueScreen from "vue-screen";

Vue.config.productionTip = false

Vue.use(VueScreen, "bootstrap");
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Loading, {
  // props
  color: "#000000",
  opacity: 1,
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
