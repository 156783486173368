<template>
  <footer class="bg-body-tertiary text-center text-lg-start">
    <!-- Copyright -->
    <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2024 Derechos reservados.
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
    name: "FooterView"
}
</script>

<style scoped></style>