<template>
  <div>
    <section class="py-5 text-center container">
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      <div style="display: none">
        <img @load="handleLoad" src="../assets/ag-bp_logo_trans.png" />
        <img @load="handleLoad" src="../assets/image_012.jpeg" />
        <img @load="handleLoad" src="../assets/image_010.jpeg" />
        <img @load="handleLoad" src="../assets/image_011.jpeg" />
        <img @load="handleLoad" src="../assets/image_002.jpeg" />
        <img @load="handleLoad" src="../assets/image_003.jpeg" />
        <img @load="handleLoad" src="../assets/image_008.jpeg" />
        <img @load="handleLoad" src="../assets/image_007.jpeg" />
      </div>
      <div class="row py-lg-3">
        <div class="col-lg-8 col-md-8 mx-auto mt-2 mb-2">
          <img alt="Vue logo" class="logo-main" src="../assets/ag-bp_logo_trans.png">          
        </div>
      </div><!-- row -->
      <div class="row">
        <div class="col-lg-10 col-md-10 mx-auto mt-2 mb-2">
          <h1 class="fw-light mb-4 text-uppercase"><b style="font-weight: 800;">¿Quiénes somos?</b></h1>
          <p class="lead text-muted">
              Somos un consorcio familiar reconocido con más de 20 años de experiencia, brindando a nuestros clientes soluciones  y servicios eficientes a sus necesidades financieras y de optimización operativa.
          </p>
          <p class="lead text-muted">
            En el año 2001 constituimos nuestra primer empresa denominada GONZÁLEZ MÁRQUEZ ASESORES, S.C. Despacho dedicado a la asesoría financiera y operativa de diversas firmas reconocidas a nivel regional.
          </p>
          <p class="lead text-muted">
            En el año 2022 constituimos JUST AUTO AG, S.A. DE C.V., empresa dedicada a la reparación y estética automotriz. Nuestro mercado objetivo los autos PREMIUM.
          </p>
        </div>
      </div><!-- row -->
    </section>

    <hr class="featurette-divider">

    <section class="py-5 text-center container" style="background-color: #f3f3f3;">
      <div class="row">
        <div class="col-lg-10 col-md-10 mx-auto mt-2 mb-2">
          <h1 class="fw-light mb-4 text-uppercase"><b style="font-weight: 800;">Con más de 10 años</b><br>de experiencia en el sector</h1>
          <p class="lead text-muted">
            Somos una empresa con más que el conocimiento y la experiencia en la reparación de autos en colisión. Nuestra dedicación está basada en el compromiso con la calidad de cada uno de los trabajos recibidos.
          </p>
          <p class="lead text-muted">
            Gracias a ello, hoy decimos con toda seguridad que somos capaces de cumplir con los compromisos y altos estándares requeridos por nuestros clientes.
          </p>
          <p class="lead text-muted">
            Es de esta forma que logramos ser una empresa dinámica que se adapta a los requerimientos actuales del sector.
          </p>
          <img class="img-thumbnail rounded" src="../assets/image_012.jpeg">
        </div>
      </div><!-- row -->
    </section>

    <hr class="featurette-divider">
    
    <section class="py-5 text-center container">
      <div class="row">
        <div class="col-lg-10 col-md-10 mx-auto mt-2 mb-2">
          <h1 class="fw-light mb-4 text-uppercase">Misión, visión y valores</h1>

          <img class="img-thumbnail rounded" src="../assets/image_010.jpeg">
        </div>
      </div><!-- row -->
    </section>

    <hr class="featurette-divider">

    <section class="py-5 text-center container" style="background-color: #f3f3f3;">
      <div class="row">
        <div class="col-lg-10 col-md-10 mx-auto mt-2 mb-2">
          <h1 class="fw-light mb-4 text-uppercase"><b style="font-weight: 800;">Nuestro recurso humano</b><br>dedicados y expertos en el rubro</h1>

          <img class="img-thumbnail rounded" src="../assets/image_011.jpeg">
        </div>
      </div><!-- row -->
    </section>

    <hr class="featurette-divider">

    <section class="py-5 text-center container">
      <div class="row">
        <div class="col-lg-10 col-md-10 mx-auto mt-2 mb-2">
          <h1 class="fw-light mb-4 text-uppercase"><b style="font-weight: 800;">Instalaciones</b><br>y materiales de trabajo</h1>

        </div>
      </div><!-- row -->

      <div class="row">
        <div class="col-lg-6 col-md-6 mx-auto">
          <img class="img-thumbnail img-fluid" src="../assets/image_002.jpeg">
        </div>
        <div class="col-lg-6 col-md-6 mx-auto">
          <img class="img-thumbnail img-fluid" src="../assets/image_003.jpeg">
        </div>
      </div><!-- row -->
      
      <div class="row">
        <div class="col-lg-6 col-md-6 mx-auto">
          <img class="img-thumbnail img-fluid" src="../assets/image_008.jpeg">
        </div>
        <div class="col-lg-6 col-md-6 mx-auto">
          <img class="img-thumbnail img-fluid" src="../assets/image_007.jpeg">
        </div>
      </div><!-- row -->
    </section>

    <hr class="featurette-divider">

    <section class="py-5 text-center container" style="background-color: #f3f3f3;">
      <div class="row">
        <div class="col-lg-10 col-md-10 mx-auto mt-2 mb-2">
          <h1 class="fw-light mb-4 text-uppercase"><b style="font-weight: 800;">Algunas empresas</b><br>con las que hemos trabajado</h1>
          
        </div>
      </div><!-- row -->
    </section>

  </div>
</template>

<script>
// @ is an alias to /src

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "MainView",
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      total: 0
    }
  },
  methods: {
    handleLoad: function(){
      this.total = this.total + 1
      if (this.total == 8) {
        this.isLoading = false;
      }
    }
  },
  created() {
    this.isLoading = true;
  }
}
</script>

<style>
  .logo-main {
    max-width: 200px;
  }
  .images {
    max-width: 500px;
  }
  h1, p {
    font-family: "Roboto", sans-serif;
  }
  p {
    text-align: justify;
  }
</style>