<template>
    <nav
      class="navbar navbar-expand-md navbar-light fixed-top main-nav"
      v-bind:class="{ 'main-nav-trans': isActive }"
      @load="handleResize"
    >
      <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="/">AG - Body & Paint</a>
        <button
          class="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
          v-if="reponsiveMenu"
        >
          Menu
          <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/acerca-de">Acerca de</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contacto">Contacto</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>
  
<script>
//   import Loading from "vue-loading-overlay";
//   import "vue-loading-overlay/dist/vue-loading.css";
  
  export default {
    name: "NavBarView",
    components: {
    //   Loading
    },
    created() {
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleResize);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("resize", this.handleResize);
    },
    data() {
      return {
        isActive: false,
        width: window.innerWidth,
        reponsiveMenu: false,
        form: {
          usrname: null,
          psswd: null
        },
        showDismissibleAlert: null,
        isLoading: false,
        fullPage: true
      };
    },
    mounted() {
      this.handleResize();
    },
    methods: {
      onSubmit() {
        
      },
      onReset() {},
      showModal() {
        this.$refs["login-modal"].show();
      },
      hideModal() {
        this.$refs["login-modal"].hide();
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs["login-modal"].toggle("#toggle-btn");
      },
      handleScroll() {
        if (window.scrollY > 300) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
      },
      handleResize: function() {
        this.width = window.innerWidth;
      },
      toTop() {
        window.scrollTo(0, 0);
      }
    }
  };
  </script>
  
  <style scoped>
  .navbar {
    font-family: "Roboto", sans-serif;
  }
  .navbar-brand {
    cursor: pointer;
  }
  
  .main-nav {
    min-height: 1.5rem !important;
    background-color: rgba(38, 38, 37, 1) !important;
  }
  
  .main-nav-trans {
    min-height: 1.5rem !important;
    background-color: rgba(38, 38, 37, 0.9) !important;
  }
  
  .main-nav .navbar-toggler {
    font-size: 80%;
    padding: 0.2rem;
    color: #64a19d;
    border: 1px solid #64a19d;
  }
  .main-nav .navbar-toggler:focus {
    outline: none;
  }
  .main-nav .navbar-brand {
    color: rgba(255, 255, 255, 0.9) !important;
    font-weight: 700;
    padding: 0.9rem 0;
  }
  .main-nav .navbar-nav .nav-item:hover {
    color: fade(#fff, 80%);
    outline: none;
    background-color: transparent;
  }
  .main-nav .navbar-nav .nav-item:active,
  .main-nav .navbar-nav .nav-item:focus {
    outline: none;
    background-color: transparent;
  }
  
  .main-nav .nav-item a {
    color: rgb(230, 230, 230);
  }
  .main-nav .nav-item a:hover {
    color: rgb(254, 254, 254);
  }
  
  @media (min-width: 992px) {
    .main-nav {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
      max-height: 60px !important;
    }
    .main-nav .navbar-brand {
      padding: 0.5rem 0;
      color: rgba(255, 255, 255, 0.5);
    }
    .main-nav .nav-link {
      transition: none;
      padding: 2rem 1.5rem;
      color: rgba(255, 255, 255, 0.9);
    }
    .main-nav .nav-link:hover {
      color: rgba(255, 255, 255, 0.75);
    }
    .main-nav .nav-link:active {
      color: #fff;
    }
    .main-nav.navbar-shrink {
      background-color: #fff;
    }
    .main-nav.navbar-shrink .navbar-brand {
      color: #000000;
    }
    .main-nav.navbar-shrink .nav-link {
      color: #000000;
      padding: 1.5rem 1.5rem 1.25rem;
      border-bottom: 0.25rem solid transparent;
    }
    .main-nav.navbar-shrink .nav-link:hover {
      color: #64a19d;
    }
    .main-nav.navbar-shrink .nav-link:active {
      color: #467370;
    }
    .main-nav.navbar-shrink .nav-link.active {
      color: #64a19d;
      outline: none;
      border-bottom: 0.25rem solid #64a19d;
    }
  }
  </style>