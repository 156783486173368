<template>
  <div class="container-fluid">
    <NavBarView />
    <main>
      <MainView />
    </main>
    <FooterView />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarView from "./NavBarView";
import MainView from "./MainView";
import FooterView from "./FooterView";

export default {
  name: "HomeView",
  components: {
    NavBarView,
    MainView,
    FooterView
  }
}
</script>

<style scoped></style>
