<template>
  <div id="app" style="min-height: 100vh;">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body, html {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
</style>